@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

$translation-links-primary: #cd5201;
$translation-links-dark: #333333;
$translation-links-background: #fff;
$translation-links-modal-overlay-bg: rgba(0, 0, 0, 0.9);

$box-shadow: #00000026;
$modal-bg: #fff;
$border-color: #ccc;

$handtalk-purple: #5b69c2;
$handtalk-light-blue: #49c2f9;
$handtalk-yellow: #fbc000;
$handtalk-orange: #fc8d00;
$handtalk-green: #00eab5;

$modal-breakpoint: 550px;

* {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-style: normal;

  canvas {
    position: relative;
    width: 100%;
    height: 100% !important;
    border-radius: 8px;
  }
}

/* Animations */
$fade-animation-speed: 0.5s;

/* Animation Fade Out */
.hide-fade-out {
  animation: fadeOut $fade-animation-speed ease-out forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}

/* Internet Explorer */
@-ms-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}

/* Animation Fade In */
.show-fade-in {
  animation: fadeIn $fade-animation-speed ease-out forwards;
}

@keyframes fadeIn {
  from {
    visibility: visible;
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadeIn {
  from {
    visibility: visible;
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeIn {
  from {
    visibility: visible;
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadeIn {
  from {
    visibility: visible;
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeIn {
  from {
    visibility: visible;
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
