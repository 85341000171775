@import '../../shared/styles.scss';

div.expired-link-info-holder {
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

div.expired-link-info-container {
  width: fit-content;
  h2 {
    color: $translation-links-primary;
    margin-bottom: 8px;
    text-align: center;
    font-size: 20px;
    font-weight: 900;
  }

  p {
    color: $translation-links-dark;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
  }
}
