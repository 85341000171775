@import '../../shared/styles.scss';

div.app-info-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
  flex: 1;
  margin-top: 16px;
}

button.app-info-translation-button {
  background-color: $translation-links-primary;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 48px;
  min-width: 48px;
  img {
    transform: translateY(0px) !important;
  }
}

.app-info-text {
  padding: 0 16px;
}
