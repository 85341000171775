@import '../../shared/styles.scss';

footer.footer {
  $badge-height: 3.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 16px;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;

      > * {
        margin: 0 8px;
      }

      a {
        height: fit-content !important;
        max-width: 50%;

        img {
          width: 100%;
        }
      }

      span.download-text {
        display: block;
        font-weight: normal;
        text-align: center;
        font-size: 24px;
        padding: 8px;
      }
    }

    .terms-of-use-link {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 140%;
      text-decoration: none;
      color: $translation-links-dark;

      span img {
        height: 16px;
        width: 16px;
        margin-right: 4px;
        transform: translateY(2px);
      }
    }

    @media screen and (max-width: 800px) {
      background-color: #fff;

      .download-text {
        display: none !important;
      }

      > div {
        margin-bottom: 16px;
      }
    }

    @media screen and (min-width: 800px) {
      flex-direction: row;

      .terms-of-use-link {
        color: black;
        font-size: 18px;
        margin-left: 1.5rem;

        span img {
          height: 18px;
          width: 18px;
        }
      }
    }
  }

  .horizontal-colored-line {
    width: 100%;
    height: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      flex: 1;
      width: 100%;
      height: 8px;
    }

    :nth-child(1) {
      background-color: $handtalk-purple;
    }

    :nth-child(2) {
      background-color: $handtalk-light-blue;
    }

    :nth-child(3) {
      background-color: $handtalk-yellow;
    }

    :nth-child(4) {
      background-color: $handtalk-orange;
    }

    :nth-child(5) {
      background-color: $handtalk-green;
    }
  }
}
