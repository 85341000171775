@import '../../shared/styles.scss';

#repeat-translation-button {
  display: flex;
  position: absolute;
  right: 16px;
  bottom: 16px;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  background-color: $translation-links-background;
  border: 0px;
  box-shadow: 0px 1px 3px 1px $box-shadow;
  box-shadow: 0px 1px 2px 0px $box-shadow;
  border-radius: 4px;
  z-index: 2;
  img {
    transform: translateY(0px) !important;
  }
}
