@import './shared/styles.scss';

body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root div.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $translation-links-background;
  overflow: hidden;
  height: 100%;

  #avatar-holder {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 16px;

    #avatar-canvas-container {
      flex-grow: 1;
      position: relative;
      width: 100%;
    }

    img {
      transform: translateY(-3.5px);
      z-index: 999;
      display: block;
    }

    @media screen and (max-height: 700px) {
      max-width: 400px;
    }
  }
}

header.header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.handtalk-loader-container {
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 3;
  background: white;
  height: 100%;
  width: 100%;
}
