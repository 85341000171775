@import '../../shared/styles.scss';

.modal-container {
  height: 100%;
  overflow-y: hidden;
  align-items: flex-start;

  @media screen and (min-width: 800px) {
    max-width: 550px;
    align-self: center;
    display: flex;
  }
  max-width: 550px;
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-container.open {
  width: 100%;
  background-color: $translation-links-modal-overlay-bg;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  justify-content: center;
  align-items: center;
  display: flex;
  @media screen and (min-width: $modal-breakpoint) {
    max-width: 100%;
    align-items: center;
    align-self: center;
  }
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  padding: 0;
  cursor: pointer;
  color: $translation-links-dark;
}

.modal-content {
  align-self: center;
}

.modal-content.open {
  background-color: #fff;
  width: 80%;
  height: auto;
  margin: auto;
  position: relative;
  overflow-y: auto;
  border-radius: 8px;
  @media screen and (min-width: 800px) {
    width: 550px;
  }
  @media screen and (max-height: 665px) {
    height: 70%;
    max-width: 400px;
  }
}

.modal-content.closed {
  height: auto;
}

.modal-content.expired {
  max-width: 406px;
  height: auto;
}
